.Tag {
  display: inline-flex;
  font-weight: 600;
  letter-spacing: 0.025em;
  align-items: center;
  border-radius: 9999px;
  white-space: nowrap;
}

.Tag--size-lg {
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  line-height: 2rem;
  height: 2rem;
}

.Tag--size-md {
  height: 28px;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  font-size: 1rem;
  line-height: 28px;
}

.Tag--size-sm {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
  line-height: 1.25rem;
  height: 1.25rem;
}

.Tag--style-filled {
  background: var(--ui-tag-filled-bg);
  border-color: var(--ui-tag-filled-border);
  color: var(--ui-tag-filled-text);

  &:hover,
  &.\:hover {
    background: var(--ui-tag-filled-hover-bg);
    border-color: var(--ui-tag-filled-hover-border);
    color: var(--ui-tag-filled-hover-text);
  }
}

.Tag--style-outlined {
  background: var(--ui-tag-outlined-bg);
  border-color: var(--ui-tag-outlined-border);
  color: var(--ui-tag-outlined-text);

  border-width: 1px;

  &:hover,
  &.\:hover {
    background: var(--ui-tag-outlined-hover-bg);
    border-color: var(--ui-tag-outlined-hover-border);
    color: var(--ui-tag-outlined-hover-text);
  }
}

.Tag--style-translucent {
  background: var(--ui-tag-translucent-bg);
  border-color: var(--ui-tag-translucent-border);
  color: var(--ui-tag-translucent-text);

  &:hover,
  &.\:hover {
    background: var(--ui-tag-translucent-hover-bg);
    border-color: var(--ui-tag-translucent-hover-border);
    color: var(--ui-tag-translucent-hover-text);
  }
}
