.stackedMeter {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  border-radius: 9999px;
  overflow: hidden;
}

.stackedMeterItem {
  transition: width 0.5s ease-in-out;
}

.lg {
  height: 16px;

  .stackedMeterItem {
    height: 16px;
  }
}

.md {
  height: 8px;

  .stackedMeterItem {
    height: 8px;
  }
}

.sm {
  height: 6px;

  .stackedMeterItem {
    height: 6px;
  }
}
