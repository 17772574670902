.SelectionButton {
  background: var(--ui-selectionbutton-bg);
  color: var(--ui-selectionbutton-text);
  border-color: var(--ui-selectionbutton-border);

  width: 10.5rem;
  display: inline-flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-duration: 100ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.025em;
  line-height: 1.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
  border-width: 1px;

  &[disabled] {
    cursor: not-allowed;
  }
}

.SelectionButton__select {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.SelectionButton__selected,
.SelectionButton__deselect {
  display: none;
}

.SelectionButton--state-selected {
  .SelectionButton__selected {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .SelectionButton__select,
  .SelectionButton__deselect {
    display: none;
  }
}

.SelectionButton--state-selected:not([disabled]):hover {
  .SelectionButton__deselect {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .SelectionButton__select,
  .SelectionButton__selected {
    display: none;
  }
}

.SelectionButton__icon {
  margin-right: 0.375rem;
  width: 1.5rem;
  height: 1.5rem;
}

.SelectionButton:not([disabled]):hover {
  background: var(--ui-selectionbutton-hover-bg);
  color: var(--ui-selectionbutton-hover-text);
  border-color: var(--ui-selectionbutton-hover-border);
}

.SelectionButton[disabled] {
  background: var(--ui-selectionbutton-disabled-bg);
  color: var(--ui-selectionbutton-disabled-text);
  border-color: var(--ui-selectionbutton-disabled-border);
  opacity: var(--ui-selectionbutton-disabled-opacity);
}

.SelectionButton--state-selected {
  background: var(--ui-selectionbutton-selected-bg);
  color: var(--ui-selectionbutton-selected-text);
  border-color: var(--ui-selectionbutton-selected-border);
}

.SelectionButton--state-selected:not([disabled]):hover {
  background: var(--ui-selectionbutton-selected-hover-bg);
  color: var(--ui-selectionbutton-selected-hover-text);
  border-color: var(--ui-selectionbutton-selected-hover-border);
}

.SelectionButton__icon {
  fill: currentColor;
}
