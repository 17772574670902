.base {
  font: var(--font-sans);
  font-weight: 500;
  color: var(--ui-heading-default-color);
}

.bold {
  composes: base;
  font-weight: 600;
}

.lg {
  composes: base;
  font-size: 32px;
  line-height: 38px;
}

.md {
  composes: base;
  font-size: 24px;
  line-height: 30px;
}

.sm {
  composes: base;
  font-size: 20px;
  line-height: 24px;
}

.xs {
  composes: base;
  font-size: 16px;
  line-height: 20px;
}
