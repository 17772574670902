.TextInput {
  display: inline-block;
  position: relative;

  input {
    width: 100%;
  }

  &--has-icon {
    &.TextInput--size-md {
      input {
        padding-left: 48px;
      }
    }

    &.TextInput--size-sm {
      input {
        padding-left: 40px;
      }
    }
  }

  &--has-right-icon {
    &.TextInput--size-md {
      input {
        padding-right: 48px;
      }
    }

    &.TextInput--size-sm {
      input {
        padding-right: 40px;
      }
    }
  }
}

.TextInput__Icon {
  position: absolute;
  z-index: 1;
  width: 24px;
  height: 24px;
  top: 12px;
  left: 12px;
  opacity: 0.5;

  &:has(+ input:hover) {
    opacity: 0.75;
  }

  &:has(+ input:not(:placeholder-shown)) {
    opacity: 0.75;
  }

  &:has(+ input:focus) {
    opacity: 1;
  }

  &:has(+ input:disabled) {
    opacity: 0.5;
  }

  .TextInput--size-sm & {
    top: 8px;
    left: 8px;
  }
}

.TextInput__Icon--right {
  left: auto;
  right: 12px;

  .TextInput--size-sm & {
    left: auto;
    right: 8px;
  }
}
