.Notification {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
}

.Notification--color-primary__text {
  color: var(--ui-notification-primary-text);
}

.Notification--color-secondary__text {
  color: var(--ui-notification-secondary-text);
}

.Notification--color-tertiary__text {
  color: var(--ui-notification-tertiary-text);
}

.Notification--size-sm,
.Notification--size-md {
  height: 1.25rem;
  width: 1.25rem;
}

.Notification--size-lg {
  height: 1.5rem;
  width: 1.5rem;
}

.Notification--color-primary {
  background: var(--ui-notification-primary-bg);
}

.Notification--color-secondary {
  background: var(--ui-notification-secondary-bg);
}

.Notification--color-tertiary {
  background: var(--ui-notification-tertiary-bg);
}
