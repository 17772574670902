.TabItem {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-bottom: 0.375rem;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-duration: 100ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  font-weight: 600;
  letter-spacing: 0.025em;
  border-bottom-width: 2px;

  &.TabItem--color-purple {
    border-color: var(--ui-tabitem-purple-border);
    color: var(--ui-tabitem-purple-text);

    &:hover,
    &.\:hover {
      border-color: var(--ui-tabitem-purple-hover-border);
      color: var(--ui-tabitem-purple-hover-text);
    }

    &.TabItem--state-inactive {
      border-color: var(--ui-tabitem-purple-inactive-border);
      color: var(--ui-tabitem-purple-inactive-text);

      &:hover,
      &.\:hover {
        border-color: var(--ui-tabitem-purple-inactive-border);
        color: var(--ui-tabitem-purple-inactive-text);
      }
    }

    &.TabItem--state-active,
    &:active {
      border-color: var(--ui-tabitem-purple-active-border);
      color: var(--ui-tabitem-purple-active-text);
    }
  }

  &.TabItem--color-neutral {
    border-color: var(--ui-tabitem-neutral-border);
    color: var(--ui-tabitem-neutral-text);

    &:hover,
    &.\:hover {
      border-color: var(--ui-tabitem-neutral-hover-border);
      color: var(--ui-tabitem-neutral-hover-text);
    }

    &.TabItem--state-inactive {
      border-color: var(--ui-tabitem-neutral-inactive-border);
      color: var(--ui-tabitem-neutral-inactive-text);

      &:hover,
      &.\:hover {
        border-color: var(--ui-tabitem-neutral-inactive-border);
        color: var(--ui-tabitem-neutral-inactive-text);
      }
    }

    &.TabItem--state-active,
    &:active {
      border-color: var(--ui-tabitem-neutral-active-border);
      color: var(--ui-tabitem-neutral-active-text);
    }
  }
}

/* TabItem sizes: sm, md, lg */
.TabItem--size-sm {
  composes: sm from "../../Label/Label.module.css";
}

.TabItem--size-md {
  composes: md from "../../Label/Label.module.css";
}

.TabItem--size-lg {
  composes: lg from "../../Label/Label.module.css";
}

/* Fix cursor on anchor elements w/o href attributes */
a.TabItem:not([href]) {
  cursor: pointer;
}
