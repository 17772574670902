@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    background: var(--body-bg);
  }

  hr {
    border-color: var(--ui-secondary);
  }
}

.screen-reader-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.shape-crisp-edges {
  shape-rendering: crispEdges;
}

@keyframes fadeInAnim {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeInAnim 0.2s ease-in-out;
}
