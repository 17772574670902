/*
 * Albra Grotesk
 */
@font-face {
  font-family: "Albra Grotesk";
  src: url("../static/fonts/Albra-Grotesk-Light.otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Albra Grotesk";
  src: url("../static/fonts/Albra-Grotesk-Light-Italic.otf");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Albra Grotesk";
  src: url("../static/fonts/Albra-Grotesk-Regular.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Albra Grotesk";
  src: url("../static/fonts/Albra-Grotesk-Regular-Italic.otf");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Albra Grotesk";
  src: url("../static/fonts/Albra-Grotesk-Medium.otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Albra Grotesk";
  src: url("../static/fonts/Albra-Grotesk-Medium-Italic.otf");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Albra Grotesk";
  src: url("../static/fonts/Albra-Grotesk-Semi.otf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Albra Grotesk";
  src: url("../static/fonts/Albra-Grotesk-Semi-Italic.otf");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Albra Grotesk";
  src: url("../static/fonts/Albra-Grotesk-Bold.otf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Albra Grotesk";
  src: url("../static/fonts/Albra-Grotesk-Bold-Italic.otf");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Albra Grotesk";
  src: url("../static/fonts/Albra-Grotesk-Black.otf");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Albra Grotesk";
  src: url("../static/fonts/Albra-Grotesk-Black-Italic.otf");
  font-weight: 800;
  font-style: italic;
}

/*
 * Albra
 */
@font-face {
  font-family: "Albra";
  src: url("../static/fonts/Albra-Light.otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Albra";
  src: url("../static/fonts/Albra-Light-Italic.otf");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Albra";
  src: url("../static/fonts/Albra-Regular.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Albra";
  src: url("../static/fonts/Albra-Regular-Italic.otf");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Albra";
  src: url("../static/fonts/Albra-Medium.otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Albra";
  src: url("../static/fonts/Albra-Medium-Italic.otf");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Albra";
  src: url("../static/fonts/Albra-Semi.otf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Albra";
  src: url("../static/fonts/Albra-Semi-Italic.otf");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Albra";
  src: url("../static/fonts/Albra-Bold.otf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Albra";
  src: url("../static/fonts/Albra-Bold-Italic.otf");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Albra";
  src: url("../static/fonts/Albra-Black.otf");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Albra";
  src: url("../static/fonts/Albra-Black-Italic.otf");
  font-weight: 800;
  font-style: italic;
}

:root {
  --font-serif: "Albra", serif;
  --font-sans: "Albra Grotesk", sans-serif;
}
