.Toggle {
  background: var(--ui-toggle-bg);

  padding: 0.125rem;
  width: 2.75rem;
  height: 1.5rem;
  border-radius: 9999px;
}

.Toggle__switch {
  background: var(--ui-toggle-switch-bg);

  transition-property: transform;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 9999px;

  border: 1px solid var(--ui-toggle-switch-border);
  box-shadow: 0px 0px 3px rgba(1, 1, 1, 0.24);
}

.Toggle__switch--selected {
  background: var(--ui-toggle-switch-selected-bg);
  border-color: var(--ui-toggle-switch-selected-border);

  transform: translateX(1.25rem);
}

.Toggle--selected {
  background: var(--ui-toggle-selected-bg);
}

.Toggle--disabled {
  cursor: not-allowed;
}
