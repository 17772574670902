.Filter {
  display: inline-block;
  position: relative;

  &--state-disabled {
    opacity: 0.5;
  }
}

.Filter__select {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  cursor: pointer;
  opacity: 0;
}

.Filter__select--state-disabled {
  cursor: not-allowed;
}

.Filter__content {
  border-color: var(--ui-select-border);

  display: inline-flex;
  position: relative;
  z-index: 10;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0.75rem;
  padding-left: 1rem;
  border-radius: 9999px;
  border-width: 1px;
  pointer-events: none;
  gap: 0.5rem;
}

.Filter__caret {
  fill: var(--ui-select-caret);
  width: 1.5rem;
  height: 1.5rem;
}

/* Workaround to allow hover state on content without pointer-events. */
.Filter:not(.Filter--state-disabled):hover .Filter__content {
  background: var(--ui-select-hover-bg);
  border-color: var(--ui-select-hover-border);
}
