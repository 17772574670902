.ArticleByline {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  gap: 0.5rem;
}

.ArticleByline > * {
  flex: 1;
}
