.Tooltip {
  background: var(--ui-tooltip-bg);
  color: var(--ui-tooltip-text);

  position: absolute;
  z-index: 9999;
  padding: 0.75rem;
  text-align: center;
  border-radius: 0.5rem;
  max-width: 224px;
  transition-property: opacity;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  pointer-events: none;
  opacity: 0;
}

.Tooltip--state-visible {
  pointer-events: auto;
  opacity: 1;
}

.Tooltip__pointer {
  fill: var(--ui-tooltip-bg);

  width: 1rem;
  position: absolute;
  z-index: 20;
}

.Tooltip__pointer--position-top {
  left: 50%;
  top: 100%;
  transform: translateX(-50%) rotate(180deg);
}

.Tooltip__pointer--position-bottom {
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  margin-top: -0.5rem;
}

.Tooltip__pointer--position-left {
  top: 50%;
  right: 0;
  transform: translateY(-50%) rotate(90deg);
  margin-right: -0.75rem;
}

.Tooltip__pointer--position-right {
  top: 50%;
  left: 0;
  transform: translateY(-50%) rotate(-90deg);
  margin-left: -0.75rem;
}

.Tooltip__target {
  cursor: help;
}
