.base {
  font-family: var(--font-sans);
  font-weight: 500;
  letter-spacing: 0.02rem;
}

.bold {
  composes: base;
  font-weight: 600;
}

.lg {
  composes: base;
  font-size: 18px;
  line-height: 19px;
}

.md {
  composes: base;
  font-size: 16px;
  line-height: 17px;
}

.sm {
  composes: base;
  font-size: 14px;
  line-height: 15px;
}

.xs {
  composes: base;
  font-size: 12px;
  line-height: 13px;
}
