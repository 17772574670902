.Modal {
  background: var(--ui-modal-bg);

  display: flex;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  justify-content: center;
  align-items: center;
}

.Modal__position {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;

  @media (min-width: 640px) {
    display: block;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @media (min-width: 768px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.Modal__dialog {
  background: var(--ui-modal-dialog-bg);
  border: 2px solid var(--ui-modal-dialog-border);

  position: relative;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-bottom: 2rem;
  padding-top: 2rem;
  width: 100%;
  min-height: 100vh;

  @media (min-width: 640px) {
    min-height: auto;
    border-radius: 0.375rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  box-shadow: 0px 2px 24px var(--ui-modal-shadow);
}

.Modal__dialog--closeable {
  padding-top: 3.5rem;
}

.Modal__heading {
  margin-bottom: 1.5rem;
  text-align: center;
}

.Modal__close {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
}
