.Badge {
  background: var(--ui-badge-bg);

  display: inline-flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.875rem;
  align-items: center;
  border-radius: 0.25rem;
}

.Badge__text {
  margin-left: 0.25rem;
}
