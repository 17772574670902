.Checkbox {
  position: relative;
  background: var(--ui-checkbox-bg);
  border: 1px solid var(--ui-checkbox-border);

  &:not(.Checkbox--disabled):hover,
  &:not(.Checkbox--disabled):focus {
    background-color: var(--ui-checkbox-hover-bg);
    border-color: var(--ui-checkbox-hover-border);
  }
}

.Checkbox--checked {
  background-color: var(--ui-checkbox-checked-bg);
  border-color: var(--ui-checkbox-checked-border);
  border-width: 0;

  &:not(.Checkbox--disabled):hover,
  &:not(.Checkbox--disabled):focus {
    background: var(--ui-checkbox-checked-hover-bg);
    border-color: var(--ui-checkbox-checked-hover-border);
  }

  .Checkmark {
    display: block;
  }
}

.Checkbox--disabled {
  opacity: 0.5;

  .Checkbox__input {
    cursor: not-allowed;
  }
}

.Checkbox__input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;

  /* Firefox fixes */
  width: 100%;
  height: 100%;
}

.Checkbox__label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &:not(.Checkbox__label--disabled):hover {
    .Checkbox:not(.Checkbox--checked) {
      background-color: var(--ui-checkbox-hover-bg);
      border-color: var(--ui-checkbox-hover-border);
    }

    .Checkbox--checked {
      background: var(--ui-checkbox-checked-hover-bg);
      border-color: var(--ui-checkbox-checked-hover-border);
    }
  }

  &--disabled {
    cursor: not-allowed;
  }
}

.Checkbox--size-sm {
  height: 1rem;
  width: 1rem;
  border-radius: 0.25rem;
}

.Checkbox--size-lg {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.375rem;
}

.Checkmark {
  display: none;
  width: 100%;
  fill: var(--content-primary);
}

.Checkbox__text {
  margin-left: 0.5rem;
}
