.ArticleMetaList {
  display: flex;
  border-top: 1px solid var(--ui-secondary);
  border-bottom: 1px solid var(--ui-secondary);
}

.ArticleMetaListItem {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  border-left: 1px solid var(--ui-secondary);
  padding: 1.25rem 1.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--content-secondary);
  white-space: nowrap;

  a {
    text-decoration: underline;
  }
}

.ArticleMetaListItem:first-child {
  border-left: 0;
  padding-left: 0;
}
