.Text--color-primary {
  color: var(--ui-text-primary-text);
}

.Text--color-secondary {
  color: var(--ui-text-secondary-text);
}

.Text--color-inactive {
  color: var(--ui-text-inactive-text);
}

.Text--color-disabled {
  color: var(--ui-text-disabled-text);
}

.Text--color-error {
  color: var(--ui-text-error-text);
}

.Text--color-success {
  color: var(--ui-text-success-text);
}

.Text--size-xs {
  /* 12/18 */
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.Text--size-sm {
  /* 14/20 */
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.Text--size-md {
  /* 16/24 */
  font-size: 1rem;
  line-height: 1.5rem;
}

.Text--size-lg {
  /* 18/26 */
  font-size: 1.125rem;
  line-height: 1.625rem;
}

.Text--size-xl {
  /* 20/28 */
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.Text--weight-thin,
.Text--weight-100 {
  font-weight: 100;
}

.Text--weight-extralight,
.Text--weight-200 {
  font-weight: 200;
}

.Text--weight-light,
.Text--weight-300 {
  font-weight: 300;
}

.Text--weight-normal,
.Text--weight-400 {
  font-weight: 400;
}

.Text--weight-medium,
.Text--weight-500 {
  font-weight: 500;
}

.Text--weight-semibold,
.Text--weight-600 {
  font-weight: 600;
}

.Text--weight-bold,
.Text--weight-700 {
  font-weight: 700;
}

.Text--weight-extrabold,
.Text--weight-800 {
  font-weight: 800;
}

.Text--weight-black,
.Text--weight-900 {
  font-weight: 900;
}
