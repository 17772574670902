.base {
  font: var(--font-sans);
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.06rem;
}

.lg {
  composes: base;
  font-size: 16px;
  line-height: 18px;
}

.md {
  composes: base;
  font-size: 14px;
  line-height: 16px;
}

.sm {
  composes: base;
  font-size: 12px;
  line-height: 14px;
}
