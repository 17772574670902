.BusinessCard {
  padding: 0.5rem;
  gap: 0.5rem;
}

.BusinessCard__avatar {
  /* This is required to override the padding enforced in Avatar. */
  padding: 0.5px;
}

.BusinessCard__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.BusinessCard__link {
  display: block;
  border-radius: 0.375rem;

  &:hover {
    background: var(--ui-businesscard-hover-bg);
  }
}
