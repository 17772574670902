.Anchor {
  align-items: center;
  gap: 8px;
  text-decoration: none;
  font-weight: 600;
  transition-property: color, fill, stroke;
  transition-duration: 100ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    text-decoration: underline;
  }

  &.Color--neutral {
    color: var(--ui-anchor-neutral-text-color);

    &:hover {
      color: var(--ui-anchor-neutral-hover-color);
    }

    &:active {
      color: var(--ui-anchor-neutral-active-color);
    }

    &:visited {
      color: var(--ui-anchor-neutral-visited-color);
    }
  }

  &.Color--purple {
    color: var(--ui-anchor-purple-text-color);

    &:hover {
      color: var(--ui-anchor-purple-hover-color);
    }

    &:active {
      color: var(--ui-anchor-purple-active-color);
    }

    &:visited {
      color: var(--ui-anchor-purple-visited-color);
    }
  }

  &.Color--danger {
    color: var(--ui-anchor-danger-text-color);

    &:hover {
      color: var(--ui-anchor-danger-hover-color);
    }

    &:active {
      color: var(--ui-anchor-danger-active-color);
    }

    &:visited {
      color: var(--ui-anchor-danger-visited-color);
    }
  }
}

.full-width {
  width: 100%;
  display: inline-flex;
}
