.Pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;

  font-size: 1rem;
  font-weight: 600;
  line-height: 1.75rem;
}

.Pagination__button {
  height: 1.75rem;
  min-width: 1.75rem;

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--ui-pagination-text);
}

.Pagination__arrowButton {
  padding: 0 0.25rem;

  &[disabled] {
    color: var(--ui-pagination-disabled-text);
  }

  &:not([disabled]):hover {
    color: var(--ui-pagination-arrow-hover-color);
  }
}

.Pagination__pageButton {
  padding: 0 0.75rem;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 9999px;
  }

  &[disabled] {
    color: var(--ui-pagination-selected-text);

    &::before {
      background: var(--ui-pagination-selected-bg);
    }
  }

  /* Ignore hover state when button is selected (disabled) */
  &:not([disabled]):hover {
    color: var(--ui-pagination-hover-text);

    &::before {
      background: var(--ui-pagination-hover-bg);
    }
  }
}

.Pagination__pageNumber {
  /* Visually center text against arrows */
  position: relative;
  top: 0.0625rem;
}

.Pagination--unordered {
  gap: 0;

  .Pagination__pageButton {
    position: relative;

    &::before {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 0.5rem;
      height: 0.5rem;
      transform: translate(-50%, -50%);
      background: var(--ui-pagination-text);
    }

    &[disabled]::before {
      background: var(--ui-pagination-selected-bg);
    }

    &:not([disabled]):hover::before {
      background: var(--ui-pagination-selected-bg);
    }
  }

  /* sr-only */
  .Pagination__pageNumber {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
}
