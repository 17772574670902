.base {
  display: inline-block;
  background-color: var(--ui-secondary);
  color: var(--content-secondary);
  border-radius: 9999px;
}

.sm {
  composes: base;
  composes: sm from "../Caps/Caps.module.css";
  padding: 4px 8px 2px 8px;
}

.md {
  composes: base;
  composes: md from "../Caps/Caps.module.css";
  padding: 6px 10px 4px 10px;
}

.lg {
  composes: base;
  composes: lg from "../Caps/Caps.module.css";
  padding: 6px 16px 4px 16px;
}
