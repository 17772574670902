.Button {
  display: inline-flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
  font-weight: 600;
  letter-spacing: 0.02rem;
  text-align: center;
  transition-property: color, background-color, box-shadow, opacity;
  transition-duration: 100ms;
  transition-timing-function: ease-in-out;
  white-space: nowrap;

  /* Button sizes: sm, md, lg, full-width */
  &.Size--lg {
    padding: 12px 24px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.06rem;
    width: 100%;
    text-transform: uppercase;
  }

  &.Size--md {
    padding: 8px 16px;
    font-size: 16px;
    line-height: 24px;
  }

  &.Size--sm {
    gap: 4px;
    padding: 8px;
    font-size: 12px;
    line-height: 16px;
  }

  &.Button--icon-only {
    padding: 8px;
  }

  /*
   * Button themes
   */
  &.Color--purple {
    &.Style--buttonPrimary {
      &:hover {
        background-color: var(--ui-button-purple-primary-hover-bg-color);
        color: var(--ui-button-purple-primary-hover-text-color);
      }

      &:active {
        background-color: var(--ui-button-purple-primary-active-bg-color);
        color: var(--ui-button-purple-primary-active-text-color);
      }

      &:focus {
        outline-color: var(--ui-button-purple-primary-focus-outline-color);
      }

      &,
      &:disabled,
      &.Button--disabled {
        background-color: var(--ui-button-purple-primary-bg-color);
        color: var(--ui-button-purple-primary-text-color);
      }
    }

    &.Style--buttonSecondary {
      &:hover {
        background-color: var(--ui-button-purple-secondary-hover-bg-color);
        color: var(--ui-button-purple-secondary-hover-text-color);
      }

      &:active {
        background-color: var(--ui-button-purple-secondary-active-bg-color);
        color: var(--ui-button-purple-secondary-active-text-color);
      }

      &:focus {
        outline-color: var(--ui-button-purple-secondary-focus-outline-color);
      }

      &,
      &:disabled,
      &.Button--disabled {
        background-color: var(--ui-button-purple-secondary-bg-color);
        color: var(--ui-button-purple-secondary-text-color);
      }
    }

    &.Style--buttonTertiary {
      &:hover {
        color: var(--ui-button-purple-tertiary-hover-text-color);
        text-decoration: underline;
      }

      &:active {
        color: var(--ui-button-purple-tertiary-active-text-color);
      }

      &:focus {
        outline-color: var(--ui-button-purple-tertiary-focus-outline-color);
      }

      &,
      &:disabled,
      &.Button--disabled {
        background-color: var(--ui-button-purple-tertiary-bg-color);
        color: var(--ui-button-purple-tertiary-text-color);
        text-decoration: none;
      }
    }
  }

  &.Color--neutral {
    &.Style--buttonPrimary {
      &:hover {
        background-color: var(--ui-button-neutral-primary-hover-bg-color);
        color: var(--ui-button-neutral-primary-hover-text-color);
        box-shadow: var(--ui-button-neutral-hover-shadow);
      }

      &:active {
        background-color: var(--ui-button-neutral-primary-active-bg-color);
        color: var(--ui-button-neutral-primary-active-text-color);
        box-shadow: none;
      }

      &:focus {
        outline-color: var(--ui-button-neutral-primary-focus-outline-color);
      }

      &,
      &:disabled,
      &.Button--disabled {
        background-color: var(--ui-button-neutral-primary-bg-color);
        color: var(--ui-button-neutral-primary-text-color);
        box-shadow: none;
      }
    }

    &.Style--buttonSecondary {
      &:hover {
        background-color: var(--ui-button-neutral-secondary-hover-bg-color);
        color: var(--ui-button-neutral-secondary-hover-text-color);
        box-shadow: var(--ui-button-neutral-hover-shadow);
      }

      &:active {
        background-color: var(--ui-button-neutral-secondary-active-bg-color);
        color: var(--ui-button-neutral-secondary-active-text-color);
        box-shadow: none;
      }

      &:focus {
        outline-color: var(--ui-button-neutral-secondary-focus-outline-color);
      }

      &,
      &:disabled,
      &.Button--disabled {
        background-color: var(--ui-button-neutral-secondary-bg-color);
        color: var(--ui-button-neutral-secondary-text-color);
        box-shadow: none;
      }
    }

    &.Style--buttonTertiary {
      &:hover {
        /*
       * This was originally a gradient in Figma, but getting the
       * gradient fill to work in conjunction with the underline
       * was too much work. Passing on it for now.
       */
        color: var(--ui-button-neutral-tertiary-hover-text-color);
        text-decoration: underline;
      }

      &:active {
        color: var(--ui-button-neutral-tertiary-active-text-color);
      }

      &:focus {
        outline-color: var(--ui-button-neutral-tertiary-focus-outline-color);
      }

      &,
      &:disabled,
      &.Button--disabled {
        background-color: var(--ui-button-neutral-tertiary-bg-color);
        color: var(--ui-button-neutral-tertiary-text-color);
        text-decoration: none;
      }
    }

    &.Style--buttonAction {
      &:hover {
        border-color: transparent;
        box-shadow: var(--ui-button-neutral-hover-shadow);
        background-color: var(--ui-button-neutral-action-hover-bg-color);
        color: var(--ui-button-neutral-action-hover-content-color);
      }

      &:active {
        color: var(--ui-button-neutral-action-active-content-color);
      }

      &,
      &:disabled,
      &.Button--disabled {
        padding: 11px;
        border-radius: 9999px;
        background-color: var(--ui-button-neutral-action-bg-color);
        border: 1px var(--ui-button-neutral-action-border-color) solid;
        color: var(--ui-button-neutral-action-content-color);
      }

      &.Size--sm {
        width: 40px;
      }
      &.Size--md {
        width: 48px;
      }
    }
  }

  &.Color--danger {
    &.Style--buttonPrimary {
      &:hover {
        background-color: var(--ui-button-danger-primary-hover-bg-color);
        color: var(--ui-button-danger-primary-hover-text-color);
      }

      &:active {
        background-color: var(--ui-button-danger-primary-active-bg-color);
        color: var(--ui-button-danger-primary-active-text-color);
      }

      &:focus {
        outline-color: var(--ui-button-danger-primary-focus-outline-color);
      }

      &,
      &:disabled,
      &.Button--disabled {
        background-color: var(--ui-button-danger-primary-bg-color);
        color: var(--ui-button-danger-primary-text-color);
      }
    }

    &.Style--buttonSecondary {
      &:hover {
        background-color: var(--ui-button-danger-secondary-hover-bg-color);
        color: var(--ui-button-danger-secondary-hover-text-color);
      }

      &:active {
        background-color: var(--ui-button-danger-secondary-active-bg-color);
        color: var(--ui-button-danger-secondary-active-text-color);
      }

      &:focus {
        outline-color: var(--ui-button-danger-secondary-focus-outline-color);
      }

      &,
      &:disabled,
      &.Button--disabled {
        background-color: var(--ui-button-danger-secondary-bg-color);
        color: var(--ui-button-danger-secondary-text-color);
      }
    }

    &.Style--buttonTertiary {
      &:hover {
        color: var(--ui-button-danger-tertiary-hover-text-color);
        text-decoration: underline;
      }

      &:active {
        color: var(--ui-button-danger-tertiary-active-text-color);
      }

      &:focus {
        outline-color: var(--ui-button-danger-tertiary-focus-outline-color);
      }

      &,
      &:disabled,
      &.Button--disabled {
        background-color: var(--ui-button-danger-tertiary-bg-color);
        color: var(--ui-button-danger-tertiary-text-color);
        text-decoration: none;
      }
    }
  }
}

.full-width {
  width: 100%;
}

.Button:disabled,
.Button--disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

/* Button elements */
.Button__icon {
  width: 24px;
  height: 24px;

  .Size--sm & {
    width: 16px;
    height: 16px;
  }
}

.Button__icon svg {
  fill: currentColor;
}

.Button__caret {
  width: 24px;
  height: 24px;
  fill: currentColor;

  .Size--sm & {
    width: 16px;
    height: 16px;
  }
}

/*
 * An annoying hack that I'm not sure how best to handle. This is the
 * only instance where the button theme has a dependency on the button size.
 */
.Size--sm {
  --ui-button-neutral-hover-shadow: var(--ui-button-neutral-hover-shadow-sm);
}
