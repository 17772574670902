.PillTab {
  display: inline-flex;
  align-items: center;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-duration: 100ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  font-weight: 600;
  letter-spacing: 0.02em;
  border-radius: 9999px;
  white-space: nowrap;
}

.PillTab--size-md {
  font-size: 1.125rem; /* 18px */
  line-height: 1.375rem; /* 22px */
  padding: 6px 14px 2px 14px;

  .PillTab__count {
    margin-top: -4px;
  }
}

.PillTab--size-sm {
  font-size: 1rem;
  line-height: 1.125rem;
  padding: 4px 12px 2px 12px;

  .PillTab__count {
    margin-top: -2px;
  }
}

/* Fix cursor on anchor elements w/o href attributes */
a.PillTab:not([href]) {
  cursor: pointer;
}

.PillTab--has-count {
  padding-right: 0.375rem;
}

.PillTab--has-icon {
  padding-right: 0.5rem;
}

.PillTab__children {
  padding-bottom: 0.125rem;
}

.PillTab__Icon {
  margin-left: 0.375rem;
}

.PillTab__count {
  margin-left: 0.375rem;
}

.PillTab--state-inactive {
  background: var(--ui-pilltab-inactive-bg);
  color: var(--ui-pilltab-inactive-text);

  &:hover,
  &.\:hover {
    background: var(--ui-pilltab-hover-bg);
    color: var(--ui-pilltab-hover-text);
  }

  &.PillTab--color-unsafe {
    background: var(--ui-pilltab-inactive-bg);
    color: var(--ui-pilltab-unsafe-inactive-text);

    &:hover,
    &.\:hover {
      background: var(--ui-pilltab-unsafe-inactive-hover-bg);
      color: var(--ui-pilltab-unsafe-inactive-hover-text);
    }
  }
}

.PillTab--state-active {
  background: var(--ui-pilltab-active-bg);
  color: var(--ui-pilltab-active-text);

  &.PillTab--color-unsafe {
    background: var(--ui-pilltab-active-bg);
    color: var(--ui-pilltab-unsafe-active-text);

    &:hover,
    &.\:hover {
      background: var(--ui-pilltab-unsafe-active-hover-bg);
      color: var(--ui-pilltab-unsafe-active-hover-text);
    }
  }
}
