.InputGroup__Top {
  display: flex;
  align-items: center;
  gap: 16px;
}

.InputGroup__Label {
  margin-bottom: 4px;
  flex: 1 1 0%;
}

.InputGroup__Required {
  color: var(--content-secondary);
}

.InputGroup__HelperTextContainer {
  margin-top: 4px;
  min-height: 18px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.InputGroup__HelperText {
  flex: 1 1 0%;
}
