.Meter {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  dl {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }
  dd {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
}

.Meter__label::after {
  content: ":";
}

.Meter__meter {
  :global(.dark) & {
    --mono-fill: var(--gradient-light);
  }

  :global(.light) & {
    --mono-fill: var(--gradient-dark);
  }

  /* By default, the meter will render in monochrome. */
  --optimum-fill: var(--mono-fill);
  --high-fill: var(--mono-fill);
  --low-fill: var(--mono-fill);

  /* If low or high attributes are set, the meter will render in color.  */
  &[low],
  &[high] {
    --optimum-fill: var(--color-green-250);
    --high-fill: var(--color-yellow-500);
    --low-fill: var(--color-red-250);
  }

  &,
  &::-webkit-meter-bar {
    width: 100%;
    border: none;
    border-radius: 9999px;
    background: var(--ui-primary);

    .Meter--size-sm & {
      height: 0.375rem;
    }

    .Meter--size-md & {
      height: 0.5rem;
    }

    .Meter--size-lg & {
      height: 0.5rem;
    }
  }

  /* WebKit */
  &::-webkit-meter-optimum-value,
  &::-webkit-meter-suboptimum-value,
  &::-webkit-meter-even-less-good-value {
    border-radius: 9999px;
  }

  &::-webkit-meter-optimum-value {
    background: var(--optimum-fill);
  }

  &::-webkit-meter-suboptimum-value {
    background: var(--high-fill);
  }

  &::-webkit-meter-even-less-good-value {
    background: var(--low-fill);
  }

  /* Mozilla */
  &::-moz-meter-bar {
    border-radius: 9999px;
  }

  &:-moz-meter-optimum::-moz-meter-bar {
    background: var(--optimum-fill);
  }

  &:-moz-meter-sub-optimum::-moz-meter-bar {
    background: var(--high-fill);
  }

  &:-moz-meter-sub-sub-optimum::-moz-meter-bar {
    background: var(--low-fill);
  }
}
