.Callout {
  padding: 12px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;

  .Callout--icon {
    width: 24px;
    margin-right: 10px;
  }

  .Callout--children {
    flex: 1 1 0%;
  }
}

.Callout--help {
  background-color: var(--ui-callout-help-bg-color);
  color: var(--ui-callout-help-text-color);
  border: 1px var(--ui-callout-help-border-color) solid;

  .Callout--icon {
    color: var(--ui-callout-help-icon-color);
  }
}

.Callout--error {
  background-color: var(--ui-callout-error-bg-color);
  color: var(--ui-callout-error-text-color);
  border: 1px var(--ui-callout-error-border-color) solid;

  .Callout--icon {
    fill: var(--ui-callout-error-icon-color);
  }
}

.Callout--success {
  background-color: var(--ui-callout-success-bg-color);
  color: var(--ui-callout-success-text-color);
  border: 1px var(--ui-callout-success-border-color) solid;

  .Callout--icon {
    fill: var(--ui-callout-success-icon-color);
  }
}

.Callout--warning {
  background-color: var(--ui-callout-warning-bg-color);
  color: var(--ui-callout-warning-text-color);
  border: 1px var(--ui-callout-warning-border-color) solid;

  .Callout--icon {
    color: var(--ui-callout-warning-icon-color);
  }
}
