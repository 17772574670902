.DescriptionTable {
  width: 100%;
  color: var(--ui-descriptiontable-text);
}

.Description:nth-child(odd) {
  background: var(--ui-descriptiontable-row-bg);
}

.DescriptionTerm {
  font-weight: 300;
  text-align: left;
}

.DescriptionDetails {
  font-weight: 500;
  text-align: right;

  a {
    text-decoration: underline;
  }
}

.DescriptionTable--size-sm {
  /* 14/20 */
  font-size: 0.875rem;
  line-height: 1.25rem;

  .DescriptionTerm,
  .DescriptionDetails {
    padding: 0.375rem;
  }
}

.DescriptionTable--size-md {
  /* 16/24 */
  font-size: 1rem;
  line-height: 1.5rem;

  .DescriptionTerm,
  .DescriptionDetails {
    padding: 0.5rem;
  }
}
