:root {
  --color-black: #000000;
  --color-white: #ffffff;

  --color-blue-100: #e3e3ff;
  --color-blue-250: #8597ff;
  --color-blue-500: #5252ff;
  --color-blue-550: #3639eb;
  --color-blue-900: #141441;

  --color-bronze-250: #fce2ce;
  --color-bronze-500: #9e4c23;

  --color-fuschia-250: #fac2ff;
  --color-fuschia-375: #f899ff;
  --color-fuschia-500: #e672f7;

  --color-green-100: #ddf0dd;
  --color-green-250: #56d67a;
  --color-green-500: #2ba32b;
  --color-green-550: #1b8525;
  --color-green-900: #0f2c14;

  --color-grey-950: #101019;
  --color-grey-900: #21212e;
  --color-grey-800: #353542;
  --color-grey-700: #565666;
  --color-grey-600: #727285;
  --color-grey-500: #aaaabf;
  --color-grey-400: #c7c7d6;
  --color-grey-300: #dadae8;
  --color-grey-200: #e9e9f5;
  --color-grey-150: #f2f2fa;
  --color-grey-100: #fbfbfd;

  --color-gray-950: var(--color-grey-950);
  --color-gray-900: var(--color-grey-900);
  --color-gray-800: var(--color-grey-800);
  --color-gray-700: var(--color-grey-700);
  --color-gray-600: var(--color-grey-600);
  --color-gray-500: var(--color-grey-500);
  --color-gray-400: var(--color-grey-400);
  --color-gray-300: var(--color-grey-300);
  --color-gray-200: var(--color-grey-200);
  --color-gray-150: var(--color-grey-150);
  --color-gray-100: var(--color-grey-100);

  --color-moonlight-250: #d7f0d3;
  --color-moonlight-500: #3888a3;
  --color-moonlight-600: #15648f;
  --color-moonlight-700: #144d80;
  --color-moonlight-800: #272c7d;
  --color-moonlight-900: #15154c;

  --color-peach-250: #fed7d5;
  --color-peach-375: #febeb7;
  --color-peach-500: #fea198;

  --color-purple-100: #ede3f9;
  --color-purple-250: #c58fff;
  --color-purple-500: #8e4fdb;
  --color-purple-550: #7439b8;
  --color-purple-900: #2a1547;

  --color-red-100: #fadfe5;
  --color-red-250: #ff7083;
  --color-red-500: #e13b5c;
  --color-red-550: #d13050;
  --color-red-900: #3b131c;

  --color-silver-500: #8c8cb8;

  --color-sky-250: #a8ccff;
  --color-sky-375: #85b1ff;
  --color-sky-500: #6c8cf5;

  --color-straw-250: #f7eed7;
  --color-straw-500: #9b8354;

  --color-yellow-100: #fcf3d9;
  --color-yellow-250: #ffdf36;
  --color-yellow-500: #efb615;
  --color-yellow-550: #d47307;
  --color-yellow-700: #ad5b00;
  --color-yellow-900: #3e2d0d;

  /*
   * Gradients
   */
  --gradient-grails-500: linear-gradient(90deg, #ff82cd 0%, #973af4 100%);

  --gradient-proof-250: linear-gradient(90deg, #f899ff 0%, #c58fff 100%);
  --gradient-proof-375: linear-gradient(90deg, #e672f7 0%, #8e4fdb 100%);
  --gradient-proof-500: linear-gradient(90deg, #b856cc 0%, #8e4fdb 100%);

  --gradient-moonlight: linear-gradient(90deg, #3888a3 0%, #d7f0d3 100%);

  --gradient-dark: linear-gradient(
    90deg,
    var(--color-grey-900) 0%,
    var(--color-grey-800) 100%
  );
  --gradient-light: linear-gradient(
    90deg,
    var(--color-grey-100) 0%,
    var(--color-grey-200) 100%
  );

  --gradient-neutral-light: linear-gradient(
    90deg,
    var(--color-grey-100) 9.9%,
    var(--color-grey-200) 47.4%,
    var(--color-grey-300) 91.15%
  );

  --gradient-neutral-dark: linear-gradient(
    90deg,
    var(--color-grey-900) 9.9%,
    var(--color-grey-800) 47.4%,
    var(--color-grey-700) 91.15%
  );

  /*
   * Content & UI
   */
  --color-light-content-primary: var(--color-grey-900);
  --color-light-content-secondary: var(--color-grey-600);
  --color-light-content-inactive: var(--color-grey-500);

  --color-light-ui-primary: var(--color-grey-300);
  --color-light-ui-secondary: var(--color-grey-200);
  --color-light-ui-tertiary: var(--color-grey-150);

  --color-dark-content-primary: var(--color-grey-100);
  --color-dark-content-secondary: var(--color-grey-500);
  --color-dark-content-inactive: var(--color-grey-700);

  --color-dark-ui-primary: var(--color-grey-800);
  --color-dark-ui-secondary: var(--color-grey-900);
  --color-dark-ui-tertiary: var(--color-grey-950);

  /*
   * Shadows/Glows
   */
  --shadow-small: 0 6px 8px rgba(0, 0, 0, 0.12);
  --shadow-medium: 0 8px 16px rgba(0, 0, 0, 0.2);
  --shadow-large: 0 16px 32px rgba(0, 0, 0, 0.24);

  --effect-dimension-small: 0 6px 8px rgba(218, 218, 232, 0.88);
  --effect-dimension-medium: 0 8px 16px rgba(218, 218, 232, 0.88);
  --effect-dimension-large: 0 16px 32px #c7c7d6;

  --glow-light-small: 0 4px 16px rgba(197, 143, 255, 0.32);
  --glow-light-medium: 0 8px 24px rgba(197, 143, 255, 0.32);
  --glow-dark-small: 0 4px 16px rgba(230, 114, 247, 0.48);
  --glow-dark-medium: 0 8px 24px rgba(230, 114, 247, 0.48);
}
