.proof.light {
  --body-bg: var(--color-grey-100);

  /*
   * Button component
   * This one is a doozy
   */

  /* Purple/primary */
  --ui-button-purple-primary-bg-color: var(--color-purple-500);
  --ui-button-purple-primary-text-color: var(--color-dark-content-primary);
  --ui-button-purple-primary-hover-bg-color: var(--color-light-content-primary);
  --ui-button-purple-primary-hover-text-color: var(
    --color-dark-content-primary
  );
  --ui-button-purple-primary-active-bg-color: var(--color-purple-550);
  --ui-button-purple-primary-active-text-color: var(--color-purple-100);
  --ui-button-purple-primary-focus-outline-color: var(--color-purple-900);

  /* Purple/secondary */
  --ui-button-purple-secondary-bg-color: var(--color-purple-100);
  --ui-button-purple-secondary-text-color: var(--color-purple-550);
  --ui-button-purple-secondary-hover-bg-color: var(
    --color-light-content-primary
  );
  --ui-button-purple-secondary-hover-text-color: var(
    --color-dark-content-primary
  );
  --ui-button-purple-secondary-active-bg-color: var(--color-purple-100);
  --ui-button-purple-secondary-active-text-color: var(--color-purple-500);
  --ui-button-purple-secondary-focus-outline-color: var(--color-purple-550);

  /* Purple/tertiary */
  --ui-button-purple-tertiary-bg-color: transparent;
  --ui-button-purple-tertiary-text-color: var(--color-purple-500);
  --ui-button-purple-tertiary-hover-text-color: var(
    --color-light-content-primary
  );
  --ui-button-purple-tertiary-active-text-color: var(--color-purple-550);
  --ui-button-purple-tertiary-focus-outline-color: var(--color-purple-500);

  /* Neutral/primary */
  --ui-button-neutral-primary-bg-color: var(--color-light-content-primary);
  --ui-button-neutral-primary-text-color: var(--color-dark-content-primary);
  --ui-button-neutral-primary-hover-bg-color: var(--color-white);
  --ui-button-neutral-primary-hover-text-color: var(
    --color-light-content-primary
  );

  --ui-button-neutral-primary-active-bg-color: var(
    --color-light-content-primary
  );
  --ui-button-neutral-primary-active-text-color: var(
    --color-dark-content-secondary
  );
  --ui-button-neutral-primary-focus-outline-color: var(--color-purple-500);

  /* Neutral/secondary */
  --ui-button-neutral-secondary-bg-color: var(--color-light-ui-secondary);
  --ui-button-neutral-secondary-text-color: var(--color-light-content-primary);
  --ui-button-neutral-secondary-hover-bg-color: var(--color-white);
  --ui-button-neutral-secondary-hover-text-color: var(
    --color-light-content-primary
  );
  --ui-button-neutral-secondary-hover-shadow: var(--glow-light-medium);
  .Size--sm {
    --ui-button-neutral-secondary-hover-shadow: var(--glow-light-small);
  }

  --ui-button-neutral-secondary-active-bg-color: var(
    --color-light-ui-secondary
  );
  --ui-button-neutral-secondary-active-text-color: var(
    --color-light-content-secondary
  );
  --ui-button-neutral-secondary-focus-outline-color: var(--color-purple-500);

  /* Neutral/tertiary */
  --ui-button-neutral-tertiary-bg-color: transparent;
  --ui-button-neutral-tertiary-text-color: var(--color-light-content-primary);
  --ui-button-neutral-tertiary-hover-text-color: var(--color-purple-500);
  --ui-button-neutral-tertiary-active-text-color: var(
    --color-light-content-secondary
  );
  --ui-button-neutral-tertiary-focus-outline-color: var(--color-purple-500);

  /* Neutral/action */
  --ui-button-neutral-action-bg-color: var(--color-white);
  --ui-button-neutral-action-content-color: var(
    --color-light-content-secondary
  );
  --ui-button-neutral-action-border-color: var(--color-light-ui-primary);
  --ui-button-neutral-action-hover-bg-color: var(--color-light-content-primary);
  --ui-button-neutral-action-hover-content-color: var(
    --color-dark-content-primary
  );
  --ui-button-neutral-action-active-content-color: var(
    --color-dark-content-secondary
  );

  /* Annoying glow */
  --ui-button-neutral-hover-shadow: var(--glow-light-medium);
  --ui-button-neutral-hover-shadow-sm: var(--glow-light-small);

  /* Danger/primary */
  --ui-button-danger-primary-bg-color: var(--color-red-500);
  --ui-button-danger-primary-text-color: var(--color-dark-content-primary);
  --ui-button-danger-primary-hover-bg-color: var(--color-light-content-primary);
  --ui-button-danger-primary-hover-text-color: var(
    --color-dark-content-primary
  );
  --ui-button-danger-primary-active-bg-color: var(--color-red-550);
  --ui-button-danger-primary-active-text-color: var(--color-red-100);
  --ui-button-danger-primary-focus-outline-color: var(--color-red-900);

  /* Danger/secondary */
  --ui-button-danger-secondary-bg-color: var(--color-red-100);
  --ui-button-danger-secondary-text-color: var(--color-red-550);
  --ui-button-danger-secondary-hover-bg-color: var(
    --color-light-content-primary
  );
  --ui-button-danger-secondary-hover-text-color: var(
    --color-dark-content-primary
  );
  --ui-button-danger-secondary-active-bg-color: var(--color-red-100);
  --ui-button-danger-secondary-active-text-color: var(--color-red-500);
  --ui-button-danger-secondary-focus-outline-color: var(--color-red-550);

  /* Danger/tertiary */
  --ui-button-danger-tertiary-bg-color: transparent;
  --ui-button-danger-tertiary-text-color: var(--color-red-500);
  --ui-button-danger-tertiary-hover-text-color: var(
    --color-light-content-primary
  );
  --ui-button-danger-tertiary-active-text-color: var(--color-red-550);
  --ui-button-danger-tertiary-focus-outline-color: var(--color-red-500);

  /* Generic Input styles */
  --ui-input-bg-color: var(--color-gray-100);
  --ui-input-text-color: var(--color-light-content-primary);
  --ui-input-border-color: var(--color-light-ui-primary);
  --ui-input-hover-border-color: var(--color-light-content-secondary);
  --ui-input-active-border-color: var(--color-purple-500);
  --ui-input-focus-bg-color: linear-gradient(
    var(--color-white),
    var(--color-white)
  );
  --ui-input-focus-border-color: var(--gradient-proof-375);
  --ui-input-focus-fallback-border-color: var(--color-purple-250);
  --ui-input-disabled-bg-color: var(--color-gray-150);
  --ui-input-disabled-border-color: var(--color-light-ui-primary);
  --ui-input-has-value-bg-color: var(--color-white);
  --ui-input-validation-success-color: var(--color-green-500);
  --ui-input-validation-error-color: var(--color-red-500);

  /* TabItem component */
  --ui-tabitem-neutral-border: transparent;
  --ui-tabitem-neutral-text: var(--color-grey-600);
  --ui-tabitem-neutral-inactive-border: transparent;
  --ui-tabitem-neutral-inactive-text: var(--color-grey-500);
  --ui-tabitem-neutral-hover-border: var(--color-grey-500);
  --ui-tabitem-neutral-hover-text: var(--color-grey-900);
  --ui-tabitem-neutral-active-border: var(--color-grey-900);
  --ui-tabitem-neutral-active-text: var(--color-grey-900);

  --ui-tabitem-purple-border: transparent;
  --ui-tabitem-purple-text: var(--color-grey-800);
  --ui-tabitem-purple-inactive-border: transparent;
  --ui-tabitem-purple-inactive-text: var(--color-grey-700);
  --ui-tabitem-purple-hover-border: var(--color-purple-500);
  --ui-tabitem-purple-hover-text: var(--color-grey-900);
  --ui-tabitem-purple-active-border: var(--color-purple-500);
  --ui-tabitem-purple-active-text: var(--color-grey-900);

  /* PillTab component */
  --ui-pilltab-inactive-bg: transparent;
  --ui-pilltab-inactive-text: var(--color-grey-700);
  --ui-pilltab-hover-bg: var(--color-purple-100);
  --ui-pilltab-hover-text: var(--color-purple-550);
  --ui-pilltab-active-bg: var(--color-grey-900);
  --ui-pilltab-active-text: var(--color-grey-100);

  --ui-pilltab-unsafe-inactive-text: var(--color-red-500);
  --ui-pilltab-unsafe-inactive-hover-bg: var(--color-red-250);
  --ui-pilltab-unsafe-inactive-hover-text: var(--color-grey-900);
  --ui-pilltab-unsafe-active-text: var(--color-red-500);
  --ui-pilltab-unsafe-active-hover-bg: var(--color-red-250);
  --ui-pilltab-unsafe-active-hover-text: var(--color-grey-900);

  /* Notification component */
  --ui-notification-primary-bg: var(--color-purple-500);
  --ui-notification-secondary-bg: var(--color-purple-100);
  --ui-notification-tertiary-bg: var(--color-grey-300);
  --ui-notification-primary-text: var(--color-grey-100);
  --ui-notification-secondary-text: var(--color-purple-550);
  --ui-notification-tertiary-text: var(--color-grey-700);

  /* Tag component */
  --ui-tag-filled-bg: var(--color-grey-150);
  --ui-tag-filled-border: transparent;
  --ui-tag-filled-text: var(--color-grey-700);

  /* TODO: discuss the use-case for hoverable tags and potential need for a
     * hoverable state, e-g- -ui-hoverable */
  --ui-tag-filled-hover-bg: var(--color-grey-200);
  --ui-tag-filled-hover-border: transparent;
  --ui-tag-filled-hover-text: var(--color-grey-900);

  --ui-tag-outlined-bg: transparent;
  --ui-tag-outlined-border: var(--color-grey-300);
  --ui-tag-outlined-text: var(--color-grey-700);

  --ui-tag-outlined-hover-bg: transparent;
  --ui-tag-outlined-hover-border: var(--color-grey-300);
  --ui-tag-outlined-hover-text: var(--color-grey-900);

  /* Translucent uses filled colors, at 72% opacity (#b8), for the background. */
  --ui-tag-translucent-bg: #f2f2fae6; /* color-grey-150 @ 72% opacity */
  --ui-tag-translucent-border: transparent;
  --ui-tag-translucent-text: var(--color-grey-700);

  --ui-tag-translucent-hover-bg: #e9e9f5e6; /* color-grey-200 @ 72% opacity */
  --ui-tag-translucent-hover-border: transparent;
  --ui-tag-translucent-hover-text: var(--color-grey-900);

  /* Link component */
  --ui-anchor-neutral-text-color: var(--color-light-content-primary);
  --ui-anchor-neutral-hover-color: var(--color-purple-500);
  --ui-anchor-neutral-active-color: var(--color-light-content-secondary);
  --ui-anchor-neutral-visited-color: var(--color-light-content-primary);

  --ui-anchor-purple-text-color: var(--color-purple-500);
  --ui-anchor-purple-hover-color: var(--color-light-content-primary);
  --ui-anchor-purple-active-color: var(--color-purple-550);
  --ui-anchor-purple-visited-color: var(--color-purple-500);

  --ui-anchor-danger-text-color: var(--color-red-500);
  --ui-anchor-danger-hover-color: var(--color-light-content-primary);
  --ui-anchor-danger-active-color: var(--color-red-550);
  --ui-anchor-danger-visited-color: var(--color-red-500);

  /* Avatar component */
  --ui-avatar-bg: var(--color-grey-150);
  --ui-avatar-text: var(--color-grey-700);
  --ui-avatar-hover-ring: var(--gradient-proof-375);
  --ui-avatar-hover-border: var(--color-white);

  /* AvatarGroup component */
  --ui-avatargroup-outline: var(--color-white);
  --ui-avatargroup-hover-outline: var(--color-purple-500);

  /* Toggle component */
  --ui-toggle-bg: var(--color-light-ui-primary);
  --ui-toggle-selected-bg: var(--gradient-proof-375);
  --ui-toggle-switch-bg: var(--color-grey-100);
  --ui-toggle-switch-border: var(--color-grey-150);
  --ui-toggle-switch-selected-bg: var(--color-grey-100);
  --ui-toggle-switch-selected-border: var(--color-purple-100);

  /* DisplayText */
  --ui-displaytext-default-color: var(--color-light-content-primary);

  /* Text component */
  --ui-text-primary-text: var(--color-light-content-primary);
  --ui-text-secondary-text: var(--color-light-content-secondary);
  --ui-text-inactive-text: var(--color-light-content-inactive);
  --ui-text-disabled-text: var(--color-light-content-inactive);

  --ui-text-error-text: var(--color-red-500);
  --ui-text-success-text: var(--color-green-500);

  /* Badge component */
  --ui-badge-bg: var(--color-light-ui-secondary);

  /* Checkbox component */
  --ui-checkbox-bg: var(--color-light-ui-tertiary);
  --ui-checkbox-border: var(--color-light-content-inactive);
  --ui-checkbox-hover-bg: var(--color-light-ui-tertiary);
  --ui-checkbox-hover-border: var(--color-light-content-secondary);
  --ui-checkbox-checked-bg: var(--color-light-content-primary);
  --ui-checkbox-checked-border: transparent;
  --ui-checkbox-checked-hover-bg: var(--gradient-proof-375);
  --ui-checkbox-checked-hover-border: transparent;

  /* Heading */
  --ui-heading-default-color: var(--color-light-content-primary);

  /* Select component */
  --ui-select-border: var(--color-light-ui-primary);
  --ui-select-caret: var(--color-light-content-primary);
  --ui-select-hover-bg: var(--color-light-ui-tertiary);
  --ui-select-hover-border: transparent;

  /* SelectionButton */
  --ui-selectionbutton-bg: var(--color-light-ui-secondary);
  --ui-selectionbutton-text: var(--color-light-content-primary);
  --ui-selectionbutton-border: transparent;
  --ui-selectionbutton-hover-bg: var(--color-dark-ui-primary);
  --ui-selectionbutton-hover-text: var(--color-dark-content-primary);
  --ui-selectionbutton-hover-border: transparent;
  --ui-selectionbutton-selected-bg: var(--color-purple-100);
  --ui-selectionbutton-selected-text: var(--color-purple-550);
  --ui-selectionbutton-selected-border: transparent;
  --ui-selectionbutton-selected-hover-bg: transparent;
  --ui-selectionbutton-selected-hover-text: var(--color-light-content-primary);
  --ui-selectionbutton-selected-hover-border: var(--color-light-ui-primary);
  --ui-selectionbutton-disabled-bg: var(--color-purple-100);
  --ui-selectionbutton-disabled-text: var(--color-purple-550);
  --ui-selectionbutton-disabled-border: transparent;
  --ui-selectionbutton-disabled-opacity: 0.64;

  /* Modal component */
  --ui-modal-bg: rgba(0, 0, 0, 0.6); /* var(--color-black) */
  --ui-modal-dialog-bg: var(--color-white);
  --ui-modal-dialog-border: var(--color-white);
  --ui-modal-shadow: rgba(
    33,
    33,
    46,
    0.32
  ); /* var(--color-light-content-primary) */

  /* Pagination component */
  --ui-pagination-bg: transparent;
  --ui-pagination-text: var(--color-light-content-secondary);
  --ui-pagination-disabled-text: var(--color-light-content-inactive);
  --ui-pagination-hover-bg: var(--color-purple-100);
  --ui-pagination-hover-text: var(--color-purple-550);
  --ui-pagination-selected-bg: var(--color-dark-ui-secondary);
  --ui-pagination-selected-text: var(--color-dark-content-primary);
  --ui-pagination-arrow-hover-color: var(--color-light-content-primary);

  /* TextInput component */
  --ui-textfield-bg: var(--color-grey-100);
  --ui-textfield-border: var(--color-light-ui-primary);
  --ui-textfield-text: var(--color-light-content-primary);
  --ui-textfield-placeholder-text: var(--color-light-content-secondary);
  --ui-textfield-focus-border: var(--color-purple-500);
  --ui-textfield-error-border: var(--color-red-500);
  --ui-textfield-success-border: var(--color-green-500);
  --ui-textfield-disabled-bg: var(--color-light-ui-tertiary);
  --ui-textfield-disabled-border: var(--color-light-ui-secondary);
  --ui-textfield-disabled-text: var(--color-light-content-inactive);

  /* Tooltip component */
  --ui-tooltip-bg: var(--color-dark-ui-primary);
  --ui-tooltip-text: var(--color-dark-content-primary);

  /* Callout component */
  --ui-callout-help-bg-color: var(--color-light-ui-tertiary);
  --ui-callout-help-text-color: var(--color-light-content-primary);
  --ui-callout-help-border-color: var(--color-light-ui-tertiary);
  --ui-callout-help-icon-color: var(--color-purple-500);

  --ui-callout-error-bg-color: var(--color-red-100);
  --ui-callout-error-text-color: var(--color-light-content-primary);
  --ui-callout-error-border-color: var(--color-red-100);
  --ui-callout-error-icon-color: var(--color-red-500);

  --ui-callout-success-bg-color: var(--color-green-100);
  --ui-callout-success-text-color: var(--color-light-content-primary);
  --ui-callout-success-border-color: var(--color-green-100);
  --ui-callout-success-icon-color: var(--color-green-500);

  --ui-callout-warning-bg-color: var(--color-yellow-100);
  --ui-callout-warning-text-color: var(--color-light-content-primary);
  --ui-callout-warning-border-color: var(--color-yellow-100);
  --ui-callout-warning-icon-color: var(--color-yellow-500);

  /* Carousel component */
  --ui-carousel-ui-color: var(--color-light-content-secondary);
  --ui-carousel-ui-hover-color: var(--color-light-content-primary);
  --ui-carousel-ui-selected-color: var(--color-light-content-primary);

  /* DescriptionTable component */
  --ui-descriptiontable-text: var(--color-light-content-primary);
  --ui-descriptiontable-row-bg: var(--color-light-ui-tertiary);

  /* BusinessCard component */
  --ui-businesscard-hover-bg: var(--color-light-ui-tertiary);

  /* UI Accents */
  --ui-primary: var(--color-light-ui-primary);
  --ui-secondary: var(--color-light-ui-secondary);
  --ui-tertiary: var(--color-light-ui-tertiary);

  /* Text & Content */
  --content-primary: var(--color-light-content-primary);
  --content-secondary: var(--color-light-content-secondary);
  --content-inactive: var(--color-light-content-inactive);

  /* Inverse colors */
  .invert-color {
    --ui-heading-default-color: var(--color-dark-content-primary);

    --ui-text-primary-text: var(--color-dark-content-primary);
    --ui-text-secondary-text: var(--color-dark-content-secondary);
    --ui-text-inactive-text: var(--color-dark-content-inactive);
    --ui-text-disabled-text: var(--color-dark-content-inactive);

    /* UI Accents */
    --ui-primary: var(--color-dark-ui-primary);
    --ui-secondary: var(--color-dark-ui-secondary);
    --ui-tertiary: var(--color-dark-ui-tertiary);

    /* Text & Content */
    --content-primary: var(--color-dark-content-primary);
    --content-secondary: var(--color-dark-content-secondary);
    --content-inactive: var(--color-dark-content-inactive);
  }
}
