.lightbox {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.mediaContainer {
  > * {
    max-width: calc(85vw);
    max-height: calc(85vh);
    object-fit: contain;
  }
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;

  svg {
    width: 48px;
    fill: #fff;
  }
}
