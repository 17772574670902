.MediaObject {
  display: flex;
  gap: 1rem;
}

.MediaObject--orientation-horizontal {
  flex-direction: row;

  &.MediaObject--align-top {
    align-items: flex-start;
  }

  &.MediaObject--align-middle {
    align-items: center;
  }

  &.MediaObject--align-bottom {
    align-items: flex-end;
  }
}

.MediaObject--orientation-vertical {
  flex-direction: column;

  &.MediaObject--align-left {
    align-items: flex-start;
    text-align: left;
  }

  &.MediaObject--align-center {
    align-items: center;
    text-align: center;
  }

  &.MediaObject--align-right {
    align-items: flex-end;
    text-align: right;
  }
}

.MediaObject--direction-reverse {
  flex-direction: row-reverse;
}

.MediaObject__media {
  display: contents;
  flex-shrink: 0;
  flex-grow: 0;
}

.MediaObject__body {
  flex: 1;
}

.MediaObject__action {
  flex: 0;
}
