.Carousel {
  height: 100%;

  :global(.slide) {
    img,
    svg,
    canvas,
    video,
    iframe {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  /* Override third-party CSS (Nuka Carousel)  */
  :global(.slider-container),
  :global(.slider-frame),
  :global(.slider-list),
  :global(.slide) {
    height: 100% !important;
  }
}

.ActiveCarousel {
  /* Increase dimensions by the height of pagination */
  /* TODO find another way that uses natural flow because pagination is not
  * guaranteed to be present. */
  --ui-pagination-gap: 0.5rem;
  --ui-pagination-height: 2rem;
  padding-bottom: calc(var(--ui-pagination-gap) + var(--ui-pagination-height));

  :global(.slider-list) {
    will-change: transform;
  }
}

/* TODO find another way that uses natural flow because pagination is not
 * guaranteed to be present. */
.CarouselPagination {
  position: absolute !important;
  top: 100% !important;
  margin: var(--ui-pagination-gap) 0 0 !important;
  left: 0;
  right: 0;
  height: var(--ui-pagination-height);
  justify-content: center;
}
