.base {
  font-family: var(--font-serif);
  font-weight: 300;
  color: var(--ui-displaytext-default-color);
}

.lg {
  composes: base;
  font-size: 64px;
  line-height: 72px;
}

.md {
  composes: base;
  font-size: 48px;
  line-height: 56px;
}

.sm {
  composes: base;
  font-size: 32px;
  line-height: 38px;
}

.bold {
  composes: base;
  font-weight: 500;
}
