.Select {
  position: relative;
}

.Select__Input {
  position: relative;
  z-index: 1;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px 8px 16px;
}

.Select__Caret {
  fill: var(--content-primary);
  width: 24px;
  height: 24px;
}
