@keyframes gradientAnim {
  0% {
    background-position-x: 0;
  }

  100% {
    background-position-x: 200%;
  }
}

.Input {
  padding: 12px 16px;
  background-color: var(--ui-input-bg-color);
  border-radius: 6px;
  border: 1px solid var(--ui-input-border-color);
  outline: none;
  color: var(--ui-input-text-color);

  &--size {
    &-sm {
      padding: 8px;
    }
  }

  &::placeholder {
    color: var(--content-secondary);
  }

  &:hover {
    border-color: var(--ui-input-hover-border-color);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: var(--ui-input-disabled-bg-color);
    border-color: var(--ui-input-disabled-border-color);
  }

  &:not(.Input--validation-success, .Input--validation-error):focus {
    border-color: var(--ui-input-focus-fallback-border-color);
  }

  &--BorderGradient {
    &:not(.Input--validation-success, .Input--validation-error):focus {
      border: 1px solid transparent;

      /*
       * This is basically Proof 375. I had to adjust it to make it seamless
       * for the animation, so it's copied here.
       */
      background: var(--ui-input-focus-bg-color) padding-box,
        linear-gradient(90deg, #e672f7 0%, #8e4fdb 50%, #e672f7 100%) border-box;
      background-size: 200% 200%;

      animation: gradientAnim 6s linear infinite;
    }
  }

  &:not(:disabled):active {
    border-color: var(--ui-input-active-border-color);
  }

  &--validation {
    padding-right: 48px;
    background-repeat: no-repeat;
    background-position: center right 16px;
    background-size: 24px 24px;

    &-success {
      border-color: var(--ui-input-validation-success-color);
      background-image: url("../images/success.svg");
    }

    &-error {
      border-color: var(--ui-input-validation-error-color);
      background-image: url("../images/error.svg");
    }
  }
}

/*
 * If using the PointerTarget, we assume that you are hiding the
 * native input element in favor of a fully custom implementation.
 */
.Input__PointerTarget {
  &.Input--has-value .Input {
    background-color: var(--ui-input-has-value-bg-color);
  }

  &.Input--disabled {
    .Input__HiddenInput {
      cursor: not-allowed;
    }

    .Input {
      background-color: var(--ui-input-disabled-bg-color);
      border-color: var(--ui-input-disabled-border-color);
    }
  }

  &:not(.Input--disabled):hover {
    .Input {
      border-color: var(--ui-input-hover-border-color);
    }
  }

  &:not(.Input--disabled):active {
    .Input {
      border-color: var(--ui-input-active-border-color);
    }
  }
}

.Input__HiddenInput {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  cursor: pointer;

  /* Required for Firefox */
  width: 100%;
}
