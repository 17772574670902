.AvatarGroup {
  display: flex;
  align-items: center;
}

.AvatarGroup--size-sm > * {
  margin-left: -0.375rem;
}

.AvatarGroup--size-md > * {
  margin-left: -0.625rem;
}

.AvatarGroup--size-lg > * {
  margin-left: -1rem;
}

.AvatarGroup__avatar {
  z-index: 1;
  border-radius: 9999px;

  .Avatar__Content {
    border-color: var(--ui-avatargroup-outline);
  }
}

.AvatarGroup__avatar--size-sm {
  outline-width: 1px;
}

.AvatarGroup__avatar--size-md {
  outline-width: 2px;
}

.AvatarGroup__avatar--size-lg {
  outline-width: 2px;
}

.AvatarGroup__avatar--hoverable {
  &:hover {
    z-index: 10;
    transform: scaleX(1.1) scaleY(1.1);
  }
}

.AvatarGroup__numHidden {
  z-index: 2;
}
