.Avatar {
  position: relative;
  border-radius: 9999px;
}

.Avatar--hoverable {
  cursor: pointer;

  &:hover {
    background: var(--ui-avatar-hover-ring);

    .Avatar__Content {
      border-color: var(--ui-avatar-hover-border);
    }
  }
}

.Avatar--active {
  background: var(--ui-avatar-hover-ring);

  .Avatar__Content {
    border-color: var(--ui-avatar-hover-border);
  }
}

.Avatar__Content {
  border: 3px solid transparent;
  border-radius: 9999px;
  height: 100%;
  overflow: hidden;
}

.Avatar__Badge {
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  background-color: var(--ui-avatar-hover-border);
  border-radius: 9999px;
}

/* Sizes: xs, sm, md, lg, xl 
 * min-width is applied to prevent avatar being "squished" in flex layouts
 */
.Avatar--size-2xs {
  min-width: 24px;
  width: 24px;
  height: 24px;
  padding: 1px;

  .Avatar__Content {
    border-width: 1px;
  }

  .Avatar__Badge {
    width: 8px;
    height: 8px;
    padding: 0.8px;
    margin: 0 1px 1px 0;
  }
}

.Avatar--size-xs {
  min-width: 32px;
  width: 2rem;
  height: 2rem;
  padding: 1px;

  .Avatar__Content {
    border-width: 1px;
  }

  .Avatar__Badge {
    width: 10px;
    height: 10px;
    padding: 0.94px;
    margin: 0 1px 1px 0;
  }
}

.Avatar--size-sm {
  min-width: 40px;
  width: 2.5rem;
  height: 2.5rem;
  padding: 2px;

  .Avatar__Content {
    border-width: 1px;
  }

  .Avatar__Badge {
    width: 12px;
    height: 12px;
    padding: 1.12px;
    margin: 0 2px 2px 0;
  }
}

.Avatar--size-md {
  min-width: 48px;
  width: 3rem;
  height: 3rem;
  padding: 2px;

  .Avatar__Content {
    border-width: 1.5px;
  }

  .Avatar__Badge {
    width: 16px;
    height: 16px;
    padding: 1.5px;
    margin: 0 2px 2px 0;
  }
}

.Avatar--size-lg {
  min-width: 64px;
  width: 4rem;
  height: 4rem;
  padding: 2px;

  .Avatar__Content {
    border-width: 2px;
  }

  .Avatar__Badge {
    width: 16px;
    height: 16px;
    padding: 1.5px;
    margin: 0 2px 2px 0;
  }
}

.Avatar--size-xl {
  min-width: 96px;
  width: 6rem;
  height: 6rem;
  padding: 3px;

  .Avatar__Badge {
    width: 24px;
    height: 24px;
    padding: 2.25px;
    margin: 0 3px 3px 0;
  }
}

.Avatar--size-2xl {
  min-width: 128px;
  width: 8rem;
  height: 8rem;
  padding: 3px;

  .Avatar__Badge {
    width: 32px;
    height: 32px;
    padding: 3px;
    margin: 0 3px 3px 0;
  }
}

.Avatar__image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/* Class to support Storybook. Should get removed on production build. */
.__Avatar__Story__Icon {
  fill: #000;

  :global(.dark) & {
    fill: #fff;
  }
}
